import { ButtonEmpty, Icon } from '@tallink/components-lib'
import NativeAdapter from '@tallink/native-adapter'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useParams } from 'react-router'
import { Prompt, useHistory } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import {
  resetOrderRequest,
  resetOrderRequestForLoggedInUser,
  setDeliveryType,
  updateSalePoint
} from '../../actions/actions'
import MirdicClient from '../../clients/MirdicClient'
import {
  BOOK_TAB_NAME,
  BREAKFAST_TYPE,
  DELIVERY_TYPE_ROOM,
  DELIVERY_TYPE_TABLE,
  ORDER_REQUEST_LIFETIME_MINUTES,
  ORDER_TAB_NAME
} from '../../common/constants'
import { ROUTE_CHANGE_CONFIRMATION_TYPES, getLocationChangeGuard } from '../../common/router'
import { ROUTES } from '../../common/routes'
import {
  convertWorkingScheduleToString,
  findSalePointByCode,
  isAlcoholSaleClosing,
  isSalePointClosed,
  isSalePointClosing,
  isSalePointUnavailable
} from '../../common/sale-points'
import BookTable from '../../components/BookTable/BookTable'
import Categories from '../../components/Categories/Categories'
import { GlobalToastActions } from '../../components/layout/GlobalToast/GlobalToast'
import Layout from '../../components/layout/Layout'
import ServiceDetails from '../../components/ServiceDetails/ServiceDetails'
import ServiceTabs from '../../components/ServiceTabs/ServiceTabs'
import useBookingPageInit from '../../hooks/useBookingPageInit'
import useScrollToTop from '../../hooks/useScrollToTop'
import {
  getOrderRequest,
  getSalePoints,
  hasSelectedProducts as hasSelectedProductsSelector,
  isSidebarOpen,
  isUserLoggedIn
} from '../../selectors/selectors'
import LoadingPage from '../LoadingPage'
import s from './ServicePage.module.scss'

export default function ServicePage() {
  const { loading } = useBookingPageInit()

  return loading ? <LoadingPage /> : <ServicePageLoaded />
}

function ServicePageLoaded() {
  useScrollToTop()
  const { t } = useTranslation()
  const { replace } = useHistory()
  const dispatch = useDispatch()
  const hasSelectedProducts = useSelector(hasSelectedProductsSelector)
  const sidebarOpen = useSelector(isSidebarOpen)
  const { salePointCode, hotelCode, tabName } = useParams()
  const [serviceDetailsVisible, setServiceDetailsVisible] = useState(false)
  const { deliveryType } = useSelector(getOrderRequest)
  const salePoints = useSelector(getSalePoints)
  const salePoint = findSalePointByCode(salePoints, salePointCode)
  const userIsLoggedIn = useSelector(isUserLoggedIn)
  const {
    minutesBeforeClosing: { alcoholic },
    isRoomDeliveryAvailable,
    isTableBookingAvailable,
    isTableDeliveryAvailable,
    type,
    name: { email, location, longDescription, phone, title, workingSchedule }
  } = salePoint

  useEffect(() => {
    if (deliveryType === DELIVERY_TYPE_TABLE && !isTableDeliveryAvailable) {
      dispatch(setDeliveryType(DELIVERY_TYPE_ROOM))
    }
    if (deliveryType === DELIVERY_TYPE_ROOM && !isRoomDeliveryAvailable) {
      dispatch(setDeliveryType(DELIVERY_TYPE_TABLE))
    }
  }, [deliveryType, dispatch, isRoomDeliveryAvailable, isTableDeliveryAvailable])

  useEffect(() => {
    let timeout

    const listener = () => {
      clearTimeout(timeout)

      timeout = setTimeout(() => {
        if (userIsLoggedIn) {
          dispatch(resetOrderRequestForLoggedInUser())
        } else {
          dispatch(resetOrderRequest())
        }
      }, ORDER_REQUEST_LIFETIME_MINUTES)
    }

    document.addEventListener('click', listener, { passive: true })

    return () => {
      clearTimeout(timeout)
      document.removeEventListener('click', listener)
    }
  }, [dispatch, userIsLoggedIn])

  useEffectOnce(() => {
    if (hotelCode === 'queen') NativeAdapter.showNativeBackButton()
    else NativeAdapter.hideNativeBackButton()
  })

  useEffectOnce(() => {
    let needToUpdateSalePoint = isSalePointClosed(salePoint)

    MirdicClient.getSalePointByCodeOrId(salePoint.salePointCode).then(salePoint => {
      const closeButtonText = t('salePointPage.toast.dismiss')
      if (isSalePointClosing(salePoint)) {
        GlobalToastActions.show({
          children: t('salePointPage.toast.onlineOrderingClosing', {
            minutesLeft: salePoint.minutesBeforeClosing.general
          }),
          closeButtonText
        })
      } else if (isAlcoholSaleClosing(salePoint)) {
        GlobalToastActions.show({
          children: t('salePointPage.toast.alcoholOrderingClosing', {
            minutesLeft: salePoint.minutesBeforeClosing.alcoholic
          }),
          closeButtonText
        })
      } else if (isSalePointUnavailable(salePoint)) {
        needToUpdateSalePoint = true
        GlobalToastActions.show({
          children: t('salePointPage.toast.unavailable'),
          type: 'warning',
          closeButtonText
        })
      } else if (isSalePointClosed(salePoint)) {
        needToUpdateSalePoint = true
        GlobalToastActions.show({
          children: t('salePointPage.toast.closed', {
            salePointName: title,
            workingSchedule:
              type === BREAKFAST_TYPE
                ? t('notificationSchedule.city.breakfast')
                : convertWorkingScheduleToString(salePoint)
          }),
          closeButtonText,
          type: 'warning'
        })
      }

      if (needToUpdateSalePoint) dispatch(updateSalePoint(salePoint))
    })
    return GlobalToastActions.hide
  })

  const toggleServiceDetailsVisibility = () => setServiceDetailsVisible(!serviceDetailsVisible)

  const handleTabClick = tabName => {
    const pathToSalePointWithTab = generatePath(ROUTES.SERVICE_PAGE, { hotelCode, salePointCode, tabName })
    replace(pathToSalePointWithTab)
  }

  return (
    <Layout
      backgroundImageUrl={salePoint.name.imageUrl2}
      headerProps={{
        title,
        backButtonVisible: hotelCode !== 'queen'
      }}
      navigationVisible={false}
      pageTitle={title}
    >
      <Prompt
        message={getLocationChangeGuard(ROUTE_CHANGE_CONFIRMATION_TYPES.REMOVE_ALL_PRODUCTS, 'SERVICE_PAGE')}
        when={hasSelectedProducts || sidebarOpen}
      />
      <div className={s['service-page']}>
        <p className={s.title}>{title}</p>
        {serviceDetailsVisible && (
          <ServiceDetails
            description={longDescription}
            email={email}
            location={location}
            phone={phone}
            workingSchedule={workingSchedule}
          />
        )}
        <ButtonEmpty className={s['show-info']} onClick={toggleServiceDetailsVisibility}>
          <span>{t('services.button.showInfo')}</span>
          <Icon
            className={s['chevron-icon']}
            color="bright-turquoise"
            size="original"
            type={serviceDetailsVisible ? 'chevron-up' : 'chevron-down'}
          />
        </ButtonEmpty>
        {isTableBookingAvailable && <ServiceTabs activeTab={tabName} onClick={handleTabClick} />}
        {tabName === ORDER_TAB_NAME && (
          <Categories
            alcoholOrderingDisabled={alcoholic === 0}
            categories={salePoint.categories}
            hotelCode={hotelCode}
            orderingDisabled={isSalePointUnavailable(salePoint) || isSalePointClosed(salePoint)}
          />
        )}
        {tabName === BOOK_TAB_NAME && <BookTable hotelCode={hotelCode} workingSchedule={workingSchedule} />}
      </div>
    </Layout>
  )
}
