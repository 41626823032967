import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import MorePropTypes from '../../common/MorePropTypes'
import { ReactComponent as AdultRoundBorder } from './assets/adult-round-border.svg'
import { ReactComponent as Adult } from './assets/adult.svg'
import { ReactComponent as ApplePayLogo } from './assets/apple-pay.svg'
import { ReactComponent as ArrowBottom } from './assets/arrow-bottom.svg'
import { ReactComponent as ArrowDownWide } from './assets/arrow-down-wide.svg'
import { ReactComponent as ArrowDown } from './assets/arrow-down.svg'
import { ReactComponent as ArrowHeight } from './assets/arrow-height.svg'
import { ReactComponent as ArrowLeft } from './assets/arrow-left.svg'
import { ReactComponent as ArrowNext } from './assets/arrow-next.svg'
import { ReactComponent as ArrowPrev } from './assets/arrow-prev.svg'
import { ReactComponent as ArrowRightInCircleFill } from './assets/arrow-right-in-circle-fill.svg'
import { ReactComponent as ArrowRight } from './assets/arrow-right.svg'
import { ReactComponent as ArrowTop } from './assets/arrow-top.svg'
import { ReactComponent as ArrowWidth } from './assets/arrow-width.svg'
import { ReactComponent as BasketSlash } from './assets/basket-slash.svg'
import { ReactComponent as Bed } from './assets/bed.svg'
import { ReactComponent as CalendarClock } from './assets/calendar-clock.svg'
import { ReactComponent as Card } from './assets/card.svg'
import { ReactComponent as Chat } from './assets/chat.svg'
import { ReactComponent as CheckInCircle } from './assets/check-in-circle.svg'
import { ReactComponent as CheckThin } from './assets/check-thin.svg'
import { ReactComponent as Check } from './assets/check.svg'
import { ReactComponent as ChevronDownInCircleFill } from './assets/chevron-down-in-circle-fill.svg'
import { ReactComponent as ChevronDown } from './assets/chevron-down.svg'
import { ReactComponent as ChevronLeftInCircleFill } from './assets/chevron-left-in-circle-fill.svg'
import { ReactComponent as ChevronLeft } from './assets/chevron-left.svg'
import { ReactComponent as ChevronRightInCircleFill } from './assets/chevron-right-in-circle-fill.svg'
import { ReactComponent as ChevronUpInCircleFill } from './assets/chevron-up-in-circle-fill.svg'
import { ReactComponent as ChevronUpUpInCircleFill } from './assets/chevron-up-up-in-circle-fill.svg'
import { ReactComponent as ChevronUpUp } from './assets/chevron-up-up.svg'
import { ReactComponent as ChevronUp } from './assets/chevron-up.svg'
import { ReactComponent as Child } from './assets/child.svg'
import { ReactComponent as Clock } from './assets/clock.svg'
import { ReactComponent as ClubOneCardBronze } from './assets/club-one-card-bronze.svg'
import { ReactComponent as ClubOneCardDefault } from './assets/club-one-card-default.svg'
import { ReactComponent as ClubOneCardDisabled } from './assets/club-one-card-disabled.svg'
import { ReactComponent as ClubOneCardGold } from './assets/club-one-card-gold.svg'
import { ReactComponent as ClubOneCardSilver } from './assets/club-one-card-silver.svg'
import { ReactComponent as CrossInCircleClose } from './assets/cross-in-circle-close.svg'
import { ReactComponent as CrossInCircle } from './assets/cross-in-circle-fill.svg'
import { ReactComponent as CrossRed } from './assets/cross-red.svg'
import { ReactComponent as CrossWhite } from './assets/cross-white.svg'
import { ReactComponent as Cross } from './assets/cross.svg'
import { ReactComponent as CustomerInCircle } from './assets/customer-in-circle.svg'
import { ReactComponent as DiscountCoupon } from './assets/discount-coupon.svg'
import { ReactComponent as Download } from './assets/download.svg'
import { ReactComponent as ExclamationCircleFill } from './assets/exclamation-circle-fill.svg'
import { ReactComponent as ExclamationTriangleFill } from './assets/exclamation-triangle-fill.svg'
import { ReactComponent as Eye } from './assets/eye.svg'
import { ReactComponent as ForkAndKnife } from './assets/fork-and-knife.svg'
import { ReactComponent as Gift } from './assets/gift.svg'
import { ReactComponent as Globe } from './assets/globe.svg'
import { ReactComponent as GooglePayLogo } from './assets/google-pay.svg'
import { ReactComponent as Handset } from './assets/handset.svg'
import { ReactComponent as Hotel } from './assets/hotel.svg'
import { ReactComponent as Junior } from './assets/junior.svg'
import { ReactComponent as KlarnaLogo } from './assets/klarna.svg'
import { ReactComponent as Lock } from './assets/lock.svg'
import { ReactComponent as MastercardLogo } from './assets/mastercard.svg'
import { ReactComponent as Meals } from './assets/meals.svg'
import { ReactComponent as MinusInCircleFill } from './assets/minus-in-circle-fill.svg'
import { ReactComponent as MinusInCircle } from './assets/minus-in-circle.svg'
import { ReactComponent as Minus } from './assets/minus.svg'
import { ReactComponent as MobilePayLogo } from './assets/mobile-pay-horizontal.svg'
import { ReactComponent as Passengers } from './assets/passengers.svg'
import { ReactComponent as PaytrailLogo } from './assets/paytrail.svg'
import { ReactComponent as Pen } from './assets/pen.svg'
import { ReactComponent as People } from './assets/people.svg'
import { ReactComponent as PercentageInCircleGreen } from './assets/percentage-in-circle-green.svg'
import { ReactComponent as PlusInCircleFill } from './assets/plus-in-circle-fill.svg'
import { ReactComponent as PlusInCircleGreen } from './assets/plus-in-circle-green.svg'
import { ReactComponent as PlusInCircle } from './assets/plus-in-circle.svg'
import { ReactComponent as Plus } from './assets/plus.svg'
import { ReactComponent as Question } from './assets/question.svg'
import { ReactComponent as Restaurant } from './assets/restaurant.svg'
import { ReactComponent as ScheduleClock } from './assets/schedule-clock.svg'
import { ReactComponent as ShipFront } from './assets/ship-front.svg'
import { ReactComponent as SignOut } from './assets/sign-out.svg'
import { ReactComponent as Star } from './assets/star.svg'
import { ReactComponent as ThreeDotsVertical } from './assets/three-dots-vertical.svg'
import { ReactComponent as TickInCircleDefault } from './assets/tick-in-circle-filled.svg'
import { ReactComponent as TickInCircle } from './assets/tick-in-circle.svg'
import { ReactComponent as Tickets } from './assets/tickets.svg'
import { ReactComponent as Trash } from './assets/trash.svg'
import { ReactComponent as TravelClass } from './assets/travel-class.svg'
import { ReactComponent as TrustlyLogo } from './assets/trustly.svg'
import { ReactComponent as Umbrella } from './assets/umbrella.svg'
import { ReactComponent as VehicleBicycle } from './assets/vehicle-bicycle.svg'
import { ReactComponent as VehicleCarXl } from './assets/vehicle-car-xl.svg'
import { ReactComponent as VehicleCar } from './assets/vehicle-car.svg'
import { ReactComponent as VehicleDisabled } from './assets/vehicle-disabled.svg'
import { ReactComponent as VehicleEmpty } from './assets/vehicle-empty.svg'
import { ReactComponent as VehicleInCircle } from './assets/vehicle-in-circle.svg'
import { ReactComponent as VehicleLongAndHigh } from './assets/vehicle-long-and-high.svg'
import { ReactComponent as VehicleLongAndLow } from './assets/vehicle-long-and-low.svg'
import { ReactComponent as VehicleLongAndMedium } from './assets/vehicle-long-and-medium.svg'
import { ReactComponent as VehicleMotorcycleWithSidecar } from './assets/vehicle-motorcycle-with-sidecar.svg'
import { ReactComponent as VehicleMotorcycle } from './assets/vehicle-motorcycle.svg'
import { ReactComponent as VehicleParkedCar } from './assets/vehicle-parked-car.svg'
import { ReactComponent as VehicleShopperCar } from './assets/vehicle-shopper-car.svg'
import { ReactComponent as VehicleShopperVan } from './assets/vehicle-shopper-van.svg'
import { ReactComponent as VehicleVanHigh } from './assets/vehicle-van-high.svg'
import { ReactComponent as VehicleVan } from './assets/vehicle-van.svg'
import { ReactComponent as Vehicles } from './assets/vehicles.svg'
import { ReactComponent as VisaLogo } from './assets/visa.svg'
import { ReactComponent as Youth } from './assets/youth.svg'
import { ReactComponent as Zoom } from './assets/zoom-icon.svg'
import s from './Icon.module.scss'

const typeToIcon = {
  'adult-round-border': AdultRoundBorder,
  'apple-pay-logo': ApplePayLogo,
  'arrow-bottom': ArrowBottom,
  'arrow-height': ArrowHeight,
  'arrow-left': ArrowLeft,
  'arrow-down': ArrowDown,
  'arrow-down-wide': ArrowDownWide,
  'arrow-right': ArrowRight,
  'arrow-right-in-circle-fill': ArrowRightInCircleFill,
  'arrow-top': ArrowTop,
  'arrow-width': ArrowWidth,
  'arrow-prev': ArrowPrev,
  'arrow-next': ArrowNext,
  'basket-slash': BasketSlash,
  'calendar-clock': CalendarClock,
  'check-in-circle': CheckInCircle,
  'check-thin': CheckThin,
  'chevron-down': ChevronDown,
  'chevron-down-in-circle-fill': ChevronDownInCircleFill,
  'chevron-left': ChevronLeft,
  'chevron-left-in-circle-fill': ChevronLeftInCircleFill,
  'chevron-right-in-circle-fill': ChevronRightInCircleFill,
  'chevron-up': ChevronUp,
  'chevron-up-in-circle-fill': ChevronUpInCircleFill,
  'chevron-up-up': ChevronUpUp,
  'chevron-up-up-in-circle-fill': ChevronUpUpInCircleFill,
  'club-one-card-bronze': ClubOneCardBronze,
  'club-one-card-default': ClubOneCardDefault,
  'club-one-card-disabled': ClubOneCardDisabled,
  'club-one-card-gold': ClubOneCardGold,
  'club-one-card-silver': ClubOneCardSilver,
  'cross-in-circle': CrossInCircle,
  'cross-in-circle-close': CrossInCircleClose,
  'cross-red': CrossRed,
  'cross-white': CrossWhite,
  'customer-in-circle': CustomerInCircle,
  'discount-coupon': DiscountCoupon,
  'exclamation-circle-fill': ExclamationCircleFill,
  'exclamation-triangle-fill': ExclamationTriangleFill,
  'fork-and-knife': ForkAndKnife,
  'google-pay-logo': GooglePayLogo,
  'klarna-logo': KlarnaLogo,
  'mastercard-logo': MastercardLogo,
  'minus-in-circle': MinusInCircle,
  'minus-in-circle-fill': MinusInCircleFill,
  'mobile-pay-logo': MobilePayLogo,
  'paytrail-logo': PaytrailLogo,
  'percentage-in-circle-green': PercentageInCircleGreen,
  'plus-in-circle': PlusInCircle,
  'plus-in-circle-green': PlusInCircleGreen,
  'plus-in-circle-fill': PlusInCircleFill,
  'ship-front': ShipFront,
  'schedule-clock': ScheduleClock,
  'sign-out': SignOut,
  'three-dots-vertical': ThreeDotsVertical,
  'tick-in-circle': TickInCircle,
  'tick-in-circle-default': TickInCircleDefault,
  'travel-class': TravelClass,
  'trustly-logo': TrustlyLogo,
  'vehicle-bicycle': VehicleBicycle,
  'vehicle-car': VehicleCar,
  'vehicle-car-xl': VehicleCarXl,
  'vehicle-disabled': VehicleDisabled,
  'vehicle-empty': VehicleEmpty,
  'vehicle-in-circle': VehicleInCircle,
  'vehicle-long-and-high': VehicleLongAndHigh,
  'vehicle-long-and-low': VehicleLongAndLow,
  'vehicle-long-and-medium': VehicleLongAndMedium,
  'vehicle-motorcycle': VehicleMotorcycle,
  'vehicle-motorcycle-with-sidecar': VehicleMotorcycleWithSidecar,
  'vehicle-parked-car': VehicleParkedCar,
  'vehicle-shopper-car': VehicleShopperCar,
  'vehicle-shopper-van': VehicleShopperVan,
  'vehicle-van': VehicleVan,
  'vehicle-van-high': VehicleVanHigh,
  'visa-logo': VisaLogo,
  adult: Adult,
  bed: Bed,
  card: Card,
  child: Child,
  chat: Chat,
  check: Check,
  clock: Clock,
  cross: Cross,
  download: Download,
  eye: Eye,
  gift: Gift,
  globe: Globe,
  handset: Handset,
  hotel: Hotel,
  junior: Junior,
  lock: Lock,
  minus: Minus,
  meals: Meals,
  passengers: Passengers,
  pen: Pen,
  people: People,
  plus: Plus,
  question: Question,
  restaurant: Restaurant,
  star: Star,
  tickets: Tickets,
  trash: Trash,
  umbrella: Umbrella,
  vehicles: Vehicles,
  youth: Youth,
  zoom: Zoom
}

const colorToClass = {
  'bright-turquoise': s['bright-turquoise'],
  'light-turquoise': s['light-turquoise'],
  'light-slate-gray': s['light-slate-gray'],
  'new-york-pink': s['new-york-pink'],
  'dark-denim': s['dark-denim'],
  buoy: s.buoy,
  default: undefined,
  geyser: s.geyser,
  white: s.white,
  viking: s.viking
}

export default function Icon({ color = 'default', className, type, size = 'm' }) {
  const IconElement = typeToIcon[type]
  if (!IconElement) throw String(`icon type '${type}' is not supported`)
  return (
    <IconElement
      className={classNames(className, colorToClass[color], s.icon, {
        [s.xxs]: size === 'xxs',
        [s.xs]: size === 'xs',
        [s.small]: size === 's',
        [s.medium]: size === 'm',
        [s.original]: size === 'original',
        [s.large]: size === 'l',
        [s.xl]: size === 'xl',
        [s.xxl]: size === 'xxl',
        [s.xxxl]: size === 'xxxl',
        [s.xxxxl]: size === 'xxxxl'
      })}
      role="img"
    />
  )
}

export function TimesIcon(props) {
  return (
    <span className={s['times-icon']} {...props}>
      ×
    </span>
  )
}

Icon.propTypes = {
  className: MorePropTypes.className,
  color: PropTypes.oneOf(Object.keys(colorToClass)),
  size: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(typeToIcon)).isRequired
}
